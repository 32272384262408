import React from 'react';
import GlobalNav from './globalNav';
import { Helmet } from "react-helmet";
import { CustomerContextProvider } from "../../contexts/CustomerContext";
import { CartContextProvider } from "../../contexts/CartContext";
import {getLabel, getLanguage, getSiteKey, isTransactional} from "../../hooks/siteData";
import { isProduction } from '../../hooks/useEnvironment';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {WebStoreContextProvider} from "../../contexts/WebStoreContext";
import WebStore from "../WebStore";
import AdminOrderHeader from "../AdminOrder/AdminOrderHeader";
import PersonaBanner from "./PersonaBanner/personaBanner";

import i18n, {loadResources} from "i18next";
import {initReactI18next, Trans} from "react-i18next";
import translationEn from "../../locales/en-us/translation.json";
import translationCnStatic from "../../locales/zh-cn-static/translation.json";
import translationEnJpDealers from "../../locales/en-jp-dealers/translation.json";
import Messages from '../Messages';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            enUs: {
                translation: translationEn
            },
            enJpDealers: {
                translation: translationEnJpDealers
            },
            zhCnStatic: {
                translation: translationCnStatic
            },
        },

        lng: getLanguage(), // if you're using a language detector, do not define the lng option
        fallbackLng: "enUs",

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

export default function Layout({ children }) {
    const siteTransactionalType = isTransactional() ? 'transactional' : 'non-transactional';
    const optanonSuffix = isProduction() ? '' : '-test';

    return (
        <CustomerContextProvider>
            <WebStoreContextProvider>
                <CartContextProvider>
                    <Helmet htmlAttributes={{class: siteTransactionalType}}>
                        <script src="//cdn.jsdelivr.net/npm/@nanoporetech-digital/components@6.8.5/dist/nano-components/nano-components.esm.js" type="module" />
                        <script type="text/javascript" src={`https://cdn.cookielaw.org/consent/4a08f01d-42dc-4d50-8960-fd46fdbbf8cf${optanonSuffix}/OtAutoBlock.js`}></script>
                        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charset="UTF-8" data-domain-script={`4a08f01d-42dc-4d50-8960-fd46fdbbf8cf${optanonSuffix}`}></script>
                        <script src="//cdn.segment.com/analytics.js/v1/osztcyjoyxoaMBPYLUA1YinlNr2eFvrC/analytics.min.js" type="text/javascript" />
                        <script src="/reddit.js" type="text/javascript" />
                        <link rel="icon" href="//nanoporetech.com/sites/default/files/s3/favicon.ico" type="image/vnd.microsoft.icon" />
                        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap" />
                    </Helmet>
                    <ToastContainer />

                    <WebStore />
                    <GlobalNav>
                        <Messages />
                        <AdminOrderHeader />
                        <PersonaBanner />
                        {children}
                    </GlobalNav>

                </CartContextProvider>
            </WebStoreContextProvider>
            <style jsx global>{`

            .contentWrapper{
                max-width:1440px;
            }

            @media screen and (max-width: 721px) {
                    .contentWrapper{
                    margin: 0 14px;
                }
             }

            @media screen and (min-width: 721px) {
                .contentWrapper {
                    margin: 0 50px;
                }
            }

        @media screen and (min-width: 1125px) {
            .contentWrapper {
                margin: 0 83px;
            }
        }
            @media screen and (min-width: 1647px) {
                .contentWrapper {
                    margin: 0 auto;
                }
            }

            .productName {
                color: #455556;
                font-weight: 600;
                font-size: 1.75rem;
                line-height: 2.6rem;
                margin-bottom: 0;
                font-size:2rem;
            }

            .headerInner {
                display: flex;
                flex-direction:row;
            }
            .productTitle {
                display: flex;
                flex-direction: column;
                margin-top: 3rem;
                line-height: 1.625rem;
                // gap: 0.2rem;
                position: relative;
                z-index: 2;
            }

            @media only screen and (max-width: 768px) {
            //  .backButton.mobile-hidden {
            //     clip: rect(0 0 0 0);
            //     position: absolute;
            //    }

               .productTitle {
                margin-top: 1.3rem!important;
            }

            .productName{
                // line-height:2rem!important;
                // max-width: 247px !important;
            }
           }

      `}</style>
        </CustomerContextProvider>
    );
}
