import React from 'react';
import UiButton from '../../../Ui/UiButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faSpinner } from '@fortawesome/pro-light-svg-icons';
import * as styles from './style.module.css';

export default function Navigate({setMode, allowBack=true, disable=false}) {

    function ButtonContent() {
        if (disable) {
            return <FontAwesomeIcon icon={faSpinner} spin />
        }

        return (
            <span>
                Continue &nbsp;&nbsp; <FontAwesomeIcon icon={faChevronRight} size="sm" />
            </span>
        );
    }

    function submitForm(e) {
        setDisable(true);
        e.target.submitForm();
    }

    return (
        <div className={styles.cta}>
            <BackButton allowBack={allowBack} setMode={setMode} />
            <UiButton type="submit" className="button button--secondary" disabled={disable}>
                <ButtonContent />
            </UiButton>
        </div>
    );
}

function BackButton({allowBack, setMode}) {
    if (!allowBack) return <div></div>;

    return (
        <UiButton className="button button--secondary button--keyline" onClick={() => setMode('')}>
            <FontAwesomeIcon icon={faChevronLeft} size="sm" /> Back
        </UiButton>
    );
}
