import React from 'react';
import PromoCard from './promoCard';
import PromoLink from './promoLink';
import { promotionItems } from './style.module.css';

export default function PromotionItems({promoCards, promoLinks}) {
    return (
        <div className={promotionItems}>
            {promoLinks.length > 0 && promoLinks.map((link, i) => <PromoLink promoLink={link} key={i} />)}
            {promoCards.length > 0 && promoCards.map((card, i) => <PromoCard promoCard={card} key={i} />)}
        </div>
    );
}
