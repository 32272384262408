import React from 'react';
import { NanoGrid } from '@nanoporetech-digital/components-react';
import ResponsiveNav from '../ResponsiveNav';
import CatalogControls from '../CatalogControls';
import CatalogContent from '../CatalogContent';
import { BlockRightMarketing, BlockFooter, BlockRightCol, BlockHelp } from '../CmsBlocks';
import * as styles from "./style.module.css";
import CategoryCms from '../CategoryCms';

export default function CatalogGrid({
    categories,
    activeCategory = 0,
    textFilter,
    setTextFilter,
    filterAttributes,
    filters,
    setFilters,
    cmsBlocks = {},
    carousel = {}
}) {
    return (
        <>
            <div style={{minHeight: "1000px"}}>
                <NanoGrid xl-cols="10" l-cols="1" m-cols="1" s-cols="1" className={styles.content}>
                    <div grid-states="xl-col-span-2 l-col-span-1 m-col-span-1 s-col-span-1">
                        <ResponsiveNav activeCategory={activeCategory} categories={categories} />
                        <BlockHelp block={cmsBlocks.help} />
                    </div>

                    <div className={styles.article} grid-states="xl-col-span-6 l-col-span-1 m-col-span-1 s-col-span-1">
                        <CatalogControls
                            textFilter={textFilter}
                            setTextFilter={setTextFilter}
                            category={activeCategory}
                            filterAttributes={filterAttributes}
                            filters={filters}
                            setFilters={setFilters}
                        />
                        <CategoryCms category={activeCategory} />
                        <CatalogContent category={activeCategory} filters={filters} textFilter={textFilter} carousel={carousel}/>
                    </div>

                    <div grid-states="xl-col-span-2 l-col-span-1 m-col-span-1 s-col-span-1">
                        <BlockRightCol block={cmsBlocks.right_col} />
                        <BlockRightMarketing block={cmsBlocks.right_marketing} />
                    </div>
                </NanoGrid>
            </div>
            <BlockFooter block={cmsBlocks.footer} />
        </>
    );
}
